import React from "react";
import { ButtonArrowRight } from "../UI/Button";
import * as style from "./Rates.module.css";

const RatesContent = ({ content }) => {
    return (
        <section>
            <div className="uk-container uk-container-expand">
                <div className={`${style.ratesContent} uk-flex uk-flex-column uk-flex-middle uk-grid-row-xxsmall`}>
                <div className="uk-width-1-1">
                        <div className="uk-grid uk-grid-match">
                            <div className="uk-width-2-5@m uk-light">
                                <div className="uk-padding-large uk-flex uk-flex-column uk-flex-middle uk-background-dusk">
                                    <i className="uk-width-1-1 uk-text-center uk-text-dusk-light uk-margin-remove-bottom"
                                        data-uk-icon="icon: history; ratio: 2.5" />
                                    <div className="uk-text-center uk-margin-bottom uk-text-dusk-lighter">
                                        Hourly Rate
                                    </div>
                                    <div className="uk-padding uk-flex uk-flex-column uk-flex-middle uk-flex-center">
                                        <span className={style.serviceCost}>
                                            $<span>200</span>USD
                                        </span>
                                        <div className="uk-text-small uk-text-center uk-text-dusk-lightest">Hourly Rate (8 hours max)</div>
                                    </div>
                                    <ButtonArrowRight
                                        url={`/contact/`}
                                        label="Let's do it"
                                        css={`uk-button-large cta-button uk-margin-top uk-text-dusk-lighter`}
                                        color={`#E0DBF3`} />
                                </div>
                            </div>
                            <div className="uk-width-3-5@m uk-flex uk-flex-column uk-flex-middle uk-flex-center uk-background-dusk-lightest">
                                <div className="uk-padding-large uk-width-1-2@m">
                                    <div>
                                        <p className="uk-text-center uk-text-large uk-text-bold">
                                            Advisory &amp; strategy
                                        </p>
                                        <p className="uk-text-center">
                                            Good for fast turnaround advisory and strategy projects.
                                        </p>
                                        <p className="uk-h4 uk-heading-line uk-text-center uk-margin-remove-top"><span>Ideal for</span></p>
                                        <ul className="uk-list uk-list-bullet uk-margin-remove">
                                            <li>Digital advisor by-the-hour</li>
                                            <li>CX/UX/UI audit &amp; strategy</li>
                                            <li>Project assesment &amp; scoping</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-1">
                        <div className="uk-grid uk-grid-match">
                            <div className="uk-width-2-5@m uk-light">
                                <div className="uk-padding-large uk-flex uk-flex-column uk-flex-middle uk-background-mustard">
                                    <i className="uk-width-1-1 uk-text-center uk-text-mustard-light uk-margin-remove-bottom"
                                        data-uk-icon="icon: calendar; ratio: 2.5" />
                                    <div className="uk-text-center uk-margin-bottom uk-text-mustard-lighter">
                                        Day Rate
                                    </div>
                                    <div className="uk-padding uk-flex uk-flex-column uk-flex-middle uk-flex-center">
                                        <span className={style.serviceCost}>
                                            $<span>800</span>USD
                                        </span>
                                        <div className="uk-text-small uk-text-center uk-text-mustard-lightest">Day Rate (3 days minimum)</div>

                                    </div>
                                    <ButtonArrowRight
                                        url={`/contact/`}
                                        label="Let's do it"
                                        css={`cta-button uk-button-large uk-margin-top uk-text-mustard-lightest`}
                                        color={`#FBEDDA`} />
                                </div>
                            </div>
                            <div className="uk-width-3-5@m uk-flex uk-flex-column uk-flex-middle uk-flex-center uk-background-mustard-lightest">
                            <div className="uk-padding-large uk-width-1-2@m">
                                    <div>
                                        <p className="uk-text-center uk-text-large uk-text-bold">
                                            Small or phased projects
                                        </p>
                                        <p className="uk-text-center">
                                            Perfect for strategic and thoughtful gigs with actionable results.
                                        </p>
                                        <p className="uk-h4 uk-heading-line uk-text-center uk-margin-remove-top"><span>Ideal for</span></p>
                                        <ul className="uk-list uk-list-bullet uk-margin-remove">
                                            <li>UX / CX research</li>
                                            <li>UX strategy workshops</li>
                                            <li>Usability testing and research</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-1">
                        <div className="uk-grid uk-grid-match">
                            <div className="uk-width-2-5@m uk-light">
                                <div className="uk-padding-large uk-flex uk-flex-column uk-flex-middle uk-background-blood">
                                    <i className="uk-width-1-1 uk-text-center uk-text-blood-light uk-margin-remove-bottom"
                                        data-uk-icon="icon: lock; ratio: 2.5" />
                                    <div className="uk-text-center uk-margin-bottom uk-text-blood-lighter">
                                        Dedicated
                                    </div>
                                    <div className="uk-padding uk-flex uk-flex-column uk-flex-middle uk-flex-center">
                                        <span className={style.serviceCost}>
                                            $<span>15,000</span>USD
                                        </span>
                                        <div className="uk-text-small uk-text-center uk-text-blood-lightest">Monthly Rate (3 months minimum)</div>

                                    </div>
                                    <ButtonArrowRight
                                        url={`/contact/`}
                                        label="Let's do it"
                                        css={`cta-button uk-button-large uk-margin-top uk-text-blood-lightest`}
                                        color={`#FBEDDA`} />
                                </div>
                            </div>
                            <div className="uk-width-3-5@m uk-flex uk-flex-column uk-flex-middle uk-flex-center uk-background-blood-lightest">
                            <div className="uk-padding-large uk-width-1-2@m">
                                    <div>
                                        <p className="uk-text-center uk-text-large uk-text-bold">
                                            End-to-end projects
                                        </p>
                                        <p className="uk-text-center">
                                            I'm dedicated full-time on your project to scope, execute, and deliver results.
                                        </p>
                                        <p className="uk-h4 uk-heading-line uk-text-center uk-margin-remove-top"><span>Ideal for</span></p>
                                        <ul className="uk-list uk-list-bullet uk-margin-remove">
                                            <li>Full-scope product design</li>
                                            <li>Long-term brand experience</li>
                                            <li>Full-stack design & development</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RatesContent;
