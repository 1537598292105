import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/withIEPolyfill"

const ToolsContent = ({ data }) => {

    const toolsQuery = useStaticQuery(graphql`
    query toolsQuery {
        allFile(filter: {relativeDirectory: {in: "tools_logos"}}) {
            nodes {
              name
              childImageSharp {
                fluid(maxWidth: 200, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
              }
            }
          }
    }`)

    const tools = toolsQuery.allFile.nodes.sort((a, b) => a.name.localeCompare(b.name));

    return (
        <section className="uk-section uk-section-large uk-background-astro-lighter">
            <div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                <div className="uk-width-4-5@m uk-margin-medium-bottom">
                    <div className="uk-padding-small uk-padding-remove-vertical uk-width-3-5@m" data-uk-scrollspy="target: > p, h3; cls: uk-animation-slide-bottom; delay: 100">
                        <p className="uk-h6 uk-text-muted uk-margin-small-bottom uk-text-uppercase">
                            <strong>&mdash;</strong> Tools of our trade
                        </p>
                        <h3 className="uk-margin-remove-top">
                            Full stack Creative Technology <span className="uk-text-nowrap">to deliver</span> experiences your <span className="uk-text-nowrap">customers will love.</span>
                        </h3>
                    </div>
                </div>
            </div>
            <div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                <div className="client-grid uk-width-5-6@m uk-grid uk-grid-collapse uk-flex-center uk-child-width-1-3 uk-child-width-1-6@m"
                     data-uk-scrollspy="target: div; cls: uk-animation-slide-bottom-medium; delay: 20">
                    {tools.map((logo, i) =>
                        <div key={i}>
                            <Img
                                fluid={logo.childImageSharp.fluid}
                                alt={logo.name}
                                className={``}
                            />
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}
export default ToolsContent;
